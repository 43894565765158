<template>
  <section>
    <b-row class="match-height">
      <div class="col-md-12 col-lg-12">
        <div class="card">
          <div class="card-header border-bottom">
            <h4 class="card-title">
              Support Tickets ({{ meta.total }})
            </h4>
            <form>
              <div class="form-group">
                <input
                  v-model="searchParam"
                  name="search"
                  placeholder="Search"
                  class="form-control"
                >
              </div>
            </form>
          </div>
          <div class="card-datatable table-responsive table-responsive-lg">
            <table class="datatables-ajax table  table-lg">
              <thead>
                <tr>
                  <th>Name</th>
                  <!-- <th>Email</th> -->
                  <th>Ticket ID</th>
                  <th>Subject</th>
                  <th>Priority</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>Name</th>
                  <!-- <th>Email</th> -->
                  <th>Ticket ID</th>
                  <th>Subject</th>
                  <th>Priority</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  v-for="(ticket, index) in ticketList"
                  :key="index"
                >
                  <td>
                    {{ index + 1 }}. {{ ticket.merchant || '---' }}
                    <small class="text-primary">{{ ticket.email || '---' }}</small>
                  </td>
                  <!-- <td>{{ ticket.email || '---' }}</td> -->
                  <td>
                    {{ ticket.ticket_no }}
                  </td>
                  <td>{{ ticket.title }}</td>
                  <td>{{ ticket.priority }}</td>
                  <td
                    :class="ticket.ticket_status === 'pending' ? 'text-warning'
                      : ticket.ticket_status === 'answered' ? 'text-success'
                        : ticket.ticket_status === 'closed' ? 'text-danger' : 'text-info'"
                  >
                    {{ ticket.ticket_status }}
                  </td>
                  <td>{{ ticket.created_at_formatted }}</td>
                  <td>
                    <b-button-group>
                      <b-link
                        :to="{ name: 'apps-ticket-edit', params: { id: ticket.id } }"
                        class="btn btn-sm btn-primary"
                      >
                        Answer
                      </b-link>
                      <a
                        :id="ticket.id"
                        href="javascript:;"
                        data-status="close"
                        class="btn btn-sm btn-danger"
                        @click="manageTicket($event)"
                      >
                        Close
                      </a>
                    </b-button-group>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-row>
    <div
      v-show="loading"
      class="overlay"
    >
      Please wait ....
    </div>
  </section>
</template>

<script>
import {
  BRow, BLink, BButtonGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BLink,
    BButtonGroup,
  },
  data() {
    return {
      data: {},
      tickets: [],
      meta: {},
      links: {},
      loading: false,
      searchParam: '',
    }
  },
  computed: {
    ticketList() {
      return this.tickets.filter(ticket => ticket.ticket_no.includes(this.searchParam) || ticket.ticket_no.toLowerCase().includes(this.searchParam))
    },
  },
  mounted() {
    this.getTickets()
  },
  methods: {
    getTickets() {
      this.$http.get(`${this.$url}/support`)
        .then(response => {
          // console.log("RESPONSE", response)
          this.tickets = response.data.data.data
          this.meta = response.data.data.meta
          this.links = response.data.data.links
        })
    },
    manageTicket(event) {
      let id = event.target.id
      this.loading = true
      this.$http.put(`${this.$url}/support/${id}/update`, { status })
        .then(response => {
          if (response.data.status) {
            window.location.reload()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss">
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0,0,0,.2);
    font-size: 20px;
    color: #fff;
  }
  table tr td:first-child {
    // width: 700px !important;
    padding: 0;
    padding-left: 7px;
  }
</style>
